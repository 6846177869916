<template>
  <div>
    <div class="story" v-if="list.length>0">
      <div class="list" v-for="(item,index) in list" :key="index" @click="handlePush(item.id)">
        <div class="left">
          <img :src="'/gsmm/api/v1/'+item.img_src" >
          <div class="center">
            <div class="title">
              {{item.title}}
            </div>
            <div class="content" style="overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;">
              {{item.remark}}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="time">
            {{item.create_time.split('-')[1]+'.'+item.create_time.split(' ')[0].split('-')[2]}}
<!--            <a-button class="buttonClass">查看并报名</a-button>-->
          </div>
          <div class="year">
            {{item.create_time.split('-')[0]}}
          </div>
        </div>
      </div>
    </div>
    <No v-if="list.length==0" />
  </div>
</template>

<script>
import No from './no.vue'

export default {
  props:{
    list:Array,
    id:Number,
  },
  data() {
    return {

    };
  },
  components:{
    No
  },
  methods: {

    handlePush(id){
      this.$router.push({
        path:'/servicedetail',
        query:{
          id:this.id,
          detailId:id
        }
      })
    }
  }
};
</script>

<style scoped lang='less'>
.buttonClass{
  background: rgba(153, 102, 0, 1);
  color: white;
  height: 34px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 250;
}
.list{
  width: 100%;
  /* background-color: red; */
  display: flex;
  height: 250px;
  padding: 50px 0;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  .left{
    display: flex;
    width: calc(100% - 200px);
    img{
      width:200px;
      height: 150px;
      border-radius: 10px;
      margin-right: 50px;
    }
    .center{
      width: calc(100% - 300px);
      margin-right: 50px;
      padding: 20px 0;
      color:#333;
      .title{

        font-size: 16px;
      }
      .content{

        font-size: 13px;
        margin-top: 10px;
        line-height: 2em;
      }
    }
  }
  .right{
    width: 200px;
    height: 100%;
    /* background-color: yellow; */
    color:#ccc;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    justify-content: center;
    border-left: 1px solid #e4e4e4;
    position: relative;
    .time{
      font-size: 36px;
      font-weight: 600;
      position: absolute;
      top: 10px;
    }
    .year{
      font-size: 20px;
      font-weight: 500;
    }
  }
}
.list:hover{
  .title{
    color:#960
  }
  .content{
    color:#960
  }
}
</style>
